import type { User } from "@/api/auth/model";

export const SET_USER = "SET_USER";

export default function (state: UserState = null, action) {
    if (action.type === SET_USER) {
        return action.user;
    }
    return state;
}

export const setUser = (user: User) => ({
    type: SET_USER,
    user,
})


export type UserState = User

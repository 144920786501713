export const SELECT_COMPANY = "SELECT_COMPANY";

export default function (state = null, action) {
    if (action.type === SELECT_COMPANY) {
        return action.company;
    }
    return state;
}

export const selectCompany = (company) => ({
    type: SELECT_COMPANY,
    company,
})

import type {CurrenciesResponse, Currency} from "@/api/currencies/model";

export const SET_CURRENCIES = "SET_CURRENCIES";

export default function (state: CurrenciesState = null, action) {
    if (action.type === SET_CURRENCIES) {
        return action.currencies;
    }
    return state;
}

export const setCurrencies = (currencies: CurrenciesResponse) => ({
    type: SET_CURRENCIES,
    currencies,
});

export type CurrenciesState = CurrenciesResponse
